import { Container } from "unstated"

import api from "../utils/api"
import deserialize from "../utils/deserialize"
import debounce from "../utils/debounce"
import get from "lodash/get"

const getAvailable = ({ available }) => available

const BILLING_CYCLES = [
  {
    available: true,
    code: "weekly",
    title: "weekly",
  },
  {
    available: true,
    code: "monthly",
    title: "monthly",
  },
]

class ProductStore extends Container {
  constructor(props) {
    super(props)

    this.setProductTiers = debounce(this.setProductTiers)
  }

  meta = {
    fetching: false,
  }

  state = {
    locations: [],
    selectedTier2Id: null,
    selectedTier3Id: null,
    locationID: null,
    selectedBillingCycle: null,
    billingCycles: [...BILLING_CYCLES],
  }

  get proxyTypes() {
    const { locations, locationID } = this.state

    if (!locationID || locations.length === 0) return []

    return get(
      locations.find(({ id }) => id === locationID),
      "features",
      []
    )
  }

  get notLaunchedProxyTypes() {
    const { locations, locationID } = this.state

    if (!locationID || locations.length === 0) return []
    // console.log("tori is", locations.find(({ id }) => id === locationID))
    return get(
      locations.find(({ id }) => id === locationID),
      "notYetLaunchedFeatures",
      []
    )
  }

  get ipRotations() {
    const { locations, locationID } = this.state

    if (!locationID || locations.length === 0) return []

    return get(
      locations.find(({ id }) => id === locationID),
      "ipRotationFeatures",
      []
    )
  }

  fetchLocations = async () => {
    if (this.meta.fetching) return
    this.meta.fetching = true
    const res = await api.get("/active_locations")
    this.meta.fetching = false
    let locations = await deserialize(res.data)
    if (locations.length === 0) {
      return console.error(`No locations found!`)
    }

    locations = locations.map(l => ({
      ...l,
      ipRotationFeatures: l.ipRotationFeatures.map(i => ({
        ...i,
        available: true,
      })),
    }))

    await this.setState({ locations })

    const location = locations
      .slice(1)
      .reduce(
        (location, i) =>
          i.typesAvailable > location.typesAvailable ? i : location,
        locations[0]
      )
    return this.setLocation(location)
  }

  setLocation = async ({ id, features, ipRotationFeatures } = {}) => {
    if (id !== this.state.locationID) {
      await this.setState({
        locationID: id,
      })
    }

    if (features.length === 0) {
      this.setState({
        selectedTier2Id: null,
        billingCycles: [...BILLING_CYCLES],
      })
    } else {
      const feature = features.find(({ available }) => available)
      if (feature) {
        this.setSelectedTier2Id(get(feature, "id"))
      } else {
        this.setState({
          selectedTier2Id: null,
          billingCycles: [...BILLING_CYCLES],
        })
      }
    }

    if (features.length === 0 || ipRotationFeatures.length === 0) {
      this.setState({ selectedTier3Id: null })
    }
    if (!this.state.selectedTier3Id) {
      this.setSelectedTier3Id(get(ipRotationFeatures, "0.id"))
    }
    this.setState({ selectedBillingCycle: null })
  }

  setSelectedTier2Id = async (id = this.state.selectedTier2Id) => {
    if (id !== this.state.selectedTier2Id) {
      const feature = this.proxyTypes
        .filter(getAvailable)
        .find(p => p.id === id)

      if (feature) {
        const isWeeklyAvailable = get(feature, "weeklyPrice", null)
        this.setState({
          billingCycles: isWeeklyAvailable
            ? [...BILLING_CYCLES]
            : [BILLING_CYCLES[1]],
          selectedBillingCycle: null,
          // ...(!isWeeklyAvailable && { selectedBillingCycle: "monthly" }),
        })
      }
      return this.setState({
        selectedTier2Id: get(feature, "id"),
      })
    }
    this.setSelectedTier3Id()
  }

  setSelectedTier3Id = async (id = this.state.selectedTier3Id) => {
    if (id !== this.state.selectedTier3Id) {
      const rotation = this.ipRotations.find(p => p.id === id)

      return this.setState({
        selectedTier3Id: get(rotation, "id"),
      })
    }
  }

  setBillingCycle = async code => {
    if (code !== this.state.billingCycle) {
      const billingCycle = this.state.billingCycles.find(p => p.code === code)
      return this.setState({
        selectedBillingCycle: get(billingCycle, "code"),
      })
    }
  }

  setProductTiers = async ({
    selectedTier2Id,
    selectedTier3Id,
    locationID,
  }) => {
    try {
      const res = await api.get("/active_locations")
      const locations = await deserialize(res.data)
      if (locations.length === 0) {
        return console.error(`No locations found!`)
      }

      await this.setState({
        locations,
        locationID,
        selectedTier2Id,
        selectedTier3Id,
      })
    } catch (e) {
      console.error(e)
    }
  }
}

export default ProductStore
