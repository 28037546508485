import React, { useState } from "react"
import styled from "styled-components"
import Modal from "./Modal"
import { LogoDiv } from "./LandingBodyHelpers"

import { MyModalBody } from "./ModalHelpers"
import { toastError } from "../utils/myToasts"
import { capitalize } from "lodash"

const StyledModal = styled(MyModalBody)`
  .container {
    font-size: 14px;
    .logo-wrapper {
      display: flex;
      justify-content: center;
    }
    .info-message-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      margin-top: 24px;
    }
    p {
      line-height: 150%;
      margin: 0;
      text-align: justify;
    }
    .input-content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 24px;
      margin-bottom: 40px;
    }
    .input-container {
      input {
        width: 100%;
        border: 2px solid #667af4;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 20px;
        line-height: 24px;
        padding: 16px;
      }
    }
  }
`

const CheckboxContainer = styled.div`
  // margin-bottom: 20px;
  flex: 0 0 48%;
  text-align: left;
  input[type="checkbox"] {
    opacity: 0;
  }

  label {
    position: relative;
    display: inline-block;
    padding-left: 16px;
    cursor: pointer;
    font-size: 16px;
    line-height: 19px;
    color: #767676;
  }

  input:disabled + label {
    cursor: progress;
    color: #a9a9a9;
  }

  label::before,
  label::after {
    transition: all 0.25s ease-in;
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  label::before {
    margin-top: 7px;
    height: 20px;
    width: 20px;

    border: 1px solid #c9c9ca;
    border-radius: 100%;

    left: -17px;
    top: -8px;
  }

  /*Checkmark of the fake checkbox*/
  label::after {
    height: 6px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: white;

    transform: rotate(-45deg);

    left: -12px;
    top: 4px;
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  input[type="checkbox"]:checked + label::before {
    content: "";
    background: #6579f3;
    border-color: #6579f3;
    border-radius: 100%;
  }
`

const ModalFooter = styled.div`
  padding: 0 10%;
  display: flex;
  margin-top: 2rem;
  justify-content: flex-end;
  gap: 26px;
  .button {
    width: 50%;
    text-align: center;
    border: 1px solid #c4c7cd;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 20px;
    line-height: 24px;
    border: 1px solid #c4c7cd;
    color: #767676;
    padding: 12px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
      text-decoration: none;
    }
    &:disabled {
      background: #b0b0b0;
      transform: scale(0.95);
    }
  }

  .button--close {
    // border: none !important;
    // color: rgb(102, 122, 244);
    font-weight: bold;
    background-color: #fff;
  }
  .button--continue {
    color: #fff;
    background-color: rgb(102, 122, 244);
    border: 1px solid transparent;
  }
`

const Checkbox = ({ labelText, id, ...rest }) => {
  return (
    <CheckboxContainer>
      <input type="checkbox" id={id} {...rest} />
      <label htmlFor={id}>{labelText}</label>
    </CheckboxContainer>
  )
}

const CryptoActValidationModal = props => {
  const {
    isOpen,
    onHide,
    onSubmit,
    mpEmail,
    paymentMethod = "COINBASE",
    askCreditAmount = false,
  } = props
  const [loading, setLoading] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [creditAmount, setCreditAmount] = useState(null)
  const [terms, setTerms] = useState(false)

  const handleSubmit = () => {
    setLoading(true)
    if (!mpEmail || mpEmail.toLowerCase() !== userEmail.trim().toLowerCase()) {
      toastError(
        "The entered email doesnot match the email of current MountProxies account"
      )
      setLoading(false)
      return
    }
    if (
      askCreditAmount &&
      (!creditAmount || creditAmount === "" || creditAmount === "0")
    ) {
      toastError("Credit amount cannot be empty")
      setLoading(false)
      return
    }
    onSubmit(askCreditAmount && creditAmount)
    setLoading(false)
  }

  const resetFields = () => {
    setLoading(false)
    setUserEmail("")
    setTerms(false)
  }

  const handleHide = () => {
    resetFields()
    onHide()
  }

  return (
    <Modal show={isOpen} onHide={handleHide}>
      <StyledModal>
        <div className="container">
          <div className="logo-wrapper">
            <LogoDiv dark fs={20} height={26} fw={400} />
          </div>
          <div className="info-message-container">
            <p>
              In order for this transaction to be properly credited to your
              account, we kindly ask you to use a {capitalize(paymentMethod)}{" "}
              account that matches the email on your MountProxies account.
            </p>
            <p>
              If your MountProxies account does not match the email on your{" "}
              {capitalize(paymentMethod)}, we encourage you to create a new
              MountProxies account that will match.
            </p>
            <p>
              Please enter your email address as acceptance that you understand
              these terms.
            </p>
          </div>
          <div className="input-content-wrapper">
            <div className="input-container">
              <input
                id="email"
                type="text"
                placeholder="Email"
                onChange={e => setUserEmail(e.target.value)}
              />
            </div>
            {askCreditAmount && (
              <div className="input-container">
                <input
                  id="askCreditAmount"
                  type="number"
                  placeholder="Add amount to credit"
                  onChange={e => setCreditAmount(e.target.value)}
                />
              </div>
            )}
            <div className="validate-checkbox">
              <Checkbox
                labelText="I understand these terms"
                checked={terms}
                onChange={() => setTerms(!terms)}
                id="terms"
              />
            </div>
          </div>
        </div>
      </StyledModal>
      <ModalFooter>
        <button
          className="button button--close cancelButton"
          onClick={handleHide}
        >
          Cancel
        </button>
        <button
          disabled={loading || !userEmail || userEmail.length === 0 || !terms}
          className="button button button--continue"
          onClick={handleSubmit}
        >
          Continue
        </button>
      </ModalFooter>
    </Modal>
  )
}
export default CryptoActValidationModal
